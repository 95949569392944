import { useState } from "react";
import type { FC } from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { Cog as CogIcon } from "../../icons/cog";
import { Lock as LockIcon } from "../../icons/lock";
import { MinusOutlined as MinusOutlinedIcon } from "../../icons/minus-outlined";
import { Template as TemplateIcon } from "../../icons/template";
import { gtm } from "../../src/gtm";

interface Feature {
  icon: any;
  items: string[];
  subheader: string;
  title: string;
}

const getFeatures = (theme: string): Feature[] => [
  {
    icon: LockIcon,
    items: ["Expiry", "Authority", "Security"],
    subheader: "Identifies issues with your websites SSL certificate",
    title: "SSL",
  },
  {
    icon: CogIcon,
    items: ["We look through 100+ best practices"],
    subheader: "Identify improvement opportunities",
    title: "Best Practices",
  },
  {
    icon: TemplateIcon,
    items: ["Color contract", "Text size", "Browser behavior"],
    subheader: "Ensure your website is available to everyone",
    title: "Accessibility",
  },
  {
    icon: TemplateIcon,
    items: ["Status", "Uptime"],
    subheader: "We check all links on your page to ensure they are operational",
    title: "Links",
  },
  {
    icon: TemplateIcon,
    items: ["Spelling", "Performance", "More"],
    subheader: "Several other audits to meet your website goals",
    title: "10+ other audits",
  },
];

export const HomeAudits: FC = (props) => {
  const theme = useTheme();
  const [selectedFeature, setSelectedFeature] = useState<number>(0);

  const features = getFeatures(theme.palette.mode);

  const handleChangeFeature = (index: number): void => {
    gtm.push({ event: "feature_click" + index });
    setSelectedFeature(index);
  };

  return (
    <Box
      sx={{
        py: 4,
      }}
      {...props}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Typography variant="h3">Audit Suite</Typography>
            <Typography
              color="textSecondary"
              sx={{ py: 2 }}
              variant="subtitle1"
            >
              Comes packed with 10+ custom web page audits each identifying
              critical functionality for your website
            </Typography>
            {features.map((feature, index) => {
              const { icon: Icon, items, subheader, title } = feature;

              const selected = index === selectedFeature;

              return (
                <Box
                  key={title}
                  onClick={() => handleChangeFeature(index)}
                  sx={{
                    backgroundColor:
                      selected && alpha(theme.palette.primary.main, 0.08),
                    borderRadius: 1,
                    cursor: selected ? "default" : "pointer",
                    display: "flex",
                    mb: 2,
                    p: 2,
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: selected && "primary.main",
                      color: selected && "primary.contrastText",
                      mr: 2,
                    }}
                  >
                    <Icon fontSize="small" />
                  </Avatar>
                  <div>
                    <Typography variant="h6">{title}</Typography>
                    <Typography color="textSecondary" variant="body2">
                      {subheader}
                    </Typography>
                    {selected && (
                      <List
                        disablePadding
                        sx={{
                          display: "grid",
                          gridTemplateColumns: items.length > 4 && {
                            sm: "repeat(2, 1fr)",
                          },
                          gap: 1,
                          pt: 2,
                        }}
                      >
                        {items.map((item) => (
                          <ListItem disableGutters key={item} sx={{ py: 0 }}>
                            <ListItemAvatar
                              sx={{
                                alignItems: "center",
                                display: "flex",
                                minWidth: 0,
                                mr: 0.5,
                              }}
                            >
                              <MinusOutlinedIcon color="primary" />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="subtitle2">
                                  {item}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </div>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
