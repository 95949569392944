import type { FC } from "react";
import { Avatar, Box, Container, Typography } from "@mui/material";

export const HomeTestimonials: FC = (props) => (
  <Box
    sx={{
      py: 15,
    }}
    {...props}
  >
    <Container
      maxWidth="md"
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography align="center" variant="h3">
        &quot;Page Auditor saved my site from going down by informing me about
        our SSL certificate expiring.&quot;
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          mt: 3,
        }}
      >
        <Avatar sx={{ mr: 2 }} variant="rounded" />
        <div>
          <Typography variant="h6">Evan,</Typography>
          <Typography variant="body2">Developer @n34t</Typography>
        </div>
      </Box>
    </Container>
  </Box>
);
