import React from "react";
import Box from "@mui/material/Box";
import { HomeHero } from "../components/home/home-hero";
import PageAuditorPricing from "../components/pricing/PageAuditorPricing";
import { title } from "../src/config";
import "../src/firebase";
import { HomeTestimonials } from "../components/home/home-testimonials";
import { HomeAudits } from "../components/home/home-audits";
import { blue } from "@mui/material/colors";
import Layout from "../components/Layout";

const description =
  "Ensure your website thrives with Page Auditor. Customize web page audits for SSL, Metrics, Spelling, and more, all powered by AI, for ultimate functionality.";
const oldDescrption =
  "Page Auditor is a powerful SaaS tool designed to help developers and marketers identify issues on their websites. It checks for errors, load times, and more.";

export default function SiteAuditor() {
  return (
    <Layout title={title} description={description}>
      <Box>
        <HomeHero />
      </Box>
      <Box>
        <HomeTestimonials />
      </Box>
      <Box>
        <HomeAudits />
      </Box>
      <Box mb={8} mt={4}>
        <PageAuditorPricing />
      </Box>
      <script
        data-color={blue[500]}
        src="https://cases.n34t.com/sdk.js"
      ></script>
    </Layout>
  );
}
