import { gtm } from "./gtm";
import { logToSlack } from "./slack";

export async function log(message: string) {
  if (process.env.NODE_ENV !== "development") {
    await logToSlack(message);
  }
  console.log(message);
}

export async function track(message: string) {
  gtm.push({ event: message });
}
