import type { FC } from "react";
import propTypes from "prop-types";
import { Box, Button, Divider, Typography } from "@mui/material";
import type { Theme } from "@mui/material";
import type { SxProps } from "@mui/system";
import { Check as CheckIcon } from "../../icons/check";
import Link from "next/link";

interface PricingPlanProps {
  cta: string;
  currency: string;
  description: string;
  features: string[];
  name: string;
  popular?: boolean;
  price: string;
  sx?: SxProps<Theme>;
  onClick?: (name: string) => void;
  link?: string;
}

export const PricingPlan: FC<PricingPlanProps> = (props) => {
  const {
    cta,
    currency,
    description,
    features,
    name,
    popular,
    price,
    sx,
    onClick,
    link,
    ...other
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h4">
            {currency}
            {price}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{
              alignSelf: "flex-end",
              ml: 1,
            }}
            variant="subtitle2"
          >
            /mo
          </Typography>
        </Box>
        <Typography sx={{ mt: 2 }} variant="h6">
          {name}
        </Typography>
        <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
          {description}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 3,
        }}
      >
        {features.map((feature) => (
          <Box
            key={feature}
            sx={{
              alignItems: "center",
              display: "flex",
              "& + &": {
                mt: 2,
              },
            }}
          >
            <CheckIcon fontSize="small" sx={{ color: "text.primary" }} />
            <Typography
              sx={{
                fontWeight: 500,
                ml: 2,
              }}
              variant="body2"
            >
              {feature}
            </Typography>
          </Box>
        ))}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Link
            href={link}
            passHref
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Button
              onClick={() => onClick && onClick(name)}
              fullWidth
              variant={popular ? "contained" : "outlined"}
            >
              {cta}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

PricingPlan.propTypes = {
  cta: propTypes.string.isRequired,
  currency: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  features: propTypes.array.isRequired,
  name: propTypes.string.isRequired,
  popular: propTypes.bool,
  price: propTypes.string.isRequired,
  sx: propTypes.object,
};
