import type { FC } from "react";
import { Avatar, Box, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from "../../icons/check-circle-outlined";
import { Users as UsersIcon } from "../../icons/users";
import { Star as StarIcon } from "../../icons/star";
import Image from "next/image";
import GetStartedButton from "../GetStartedButton";
import Link from "next/link";
import Grid from "@mui/material/Grid";

export const HomeHero: FC = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        pt: 6,
      }}
      {...props}
    >
      <Container
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          mt={8}
          mb={14}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Grid container>
            <Grid item md={12} lg={6}>
              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography color="primary" variant="overline">
                  Introducing
                </Typography>
                <Typography align="center" variant="h1" sx={{ my: 2 }}>
                  Page Auditor
                </Typography>
                <Typography align="center" variant="h4">
                  Know when something goes wrong with your website,{" "}
                  <em>before your users do! </em>
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="h6"
                  sx={{ py: 3 }}
                >
                  PageAuditor provides website quality assurance powered by
                  artificial intelligence.
                </Typography>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{
                    maxWidth: 500,
                    mx: -1,
                    mb: 0,
                    "& > a": {
                      m: 1,
                    },
                  }}
                >
                  <GetStartedButton />
                </Box>
                <Box
                  sx={{
                    flexWrap: "wrap",
                    alignItems: {
                      sm: "center",
                      xs: "flex-start",
                    },
                    display: "flex",
                    flexDirection: {
                      sm: "row",
                      xs: "column",
                    },
                    py: 3,
                    m: -1,
                    "& > *": {
                      m: 1,
                    },
                  }}
                >
                  {[
                    "SSL",
                    "Console",
                    "Metrics",
                    "Links",
                    "Spelling",
                    "10+ total audits",
                  ].map((item) => (
                    <Box
                      key={item}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        m: 2,
                      }}
                    >
                      <CheckCircleOutlinedIcon color="success" sx={{ mr: 1 }} />
                      <Typography variant="subtitle2">{item}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} lg={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="100%"
              >
                <Box sx={{ border: "1px solid #ccc", borderRadius: 2 }}>
                  <iframe
                    style={{ marginBottom: -4 }}
                    width="373"
                    height="315"
                    src="https://www.youtube.com/embed/f7g4pvVa_oI?controls=0&autoplay=1&modestbranding=1&mute=1&loop=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </Box>
                <Typography sx={{ mt: 1 }} variant="caption">
                  Want to see for yourself? Check out our{" "}
                  <Link href="/demo" passHref>
                    live demo.
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Typography align="center" variant="subtitle1">
          We've created cutting edge website analysis techniques that are used
          at the worlds biggest companies and make them available to anyone.
        </Typography>

        <Box width="100%">
          <Metrics />
        </Box>
        <Box width="100%">
          <WorksWith />
        </Box>
      </Container>
      <Box
        sx={{
          maxWidth: 980,
          width: "100%",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            pt: "calc(600 / 980 * 100%)",
            "& img": {
              height: "auto",
              position: "absolute",
              top: 0,
              width: "100%",
            },
            overflow: "hidden",
          }}
        >
          <img alt="" src={`/example-audit.png`} />
        </Box>
      </Box>
    </Box>
  );
};

function WorksWith() {
  return (
    <Box sx={{ py: 4 }}>
      <Typography align="center" variant="subtitle2">
        Works with all your favorite website providers and platforms
      </Typography>
      <Container
        maxWidth="md"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          px: 3,
        }}
      >
        <Box
          flex={1}
          display="flex"
          justifyContent={"center"}
          mb={1}
          mt={4}
          sx={{ filter: "grayscale(1)" }}
        >
          <Image
            alt="Shopify logo"
            style={{ filter: "opacity(0.5)" }}
            src={"/shopify/png/shopify_glyph.png"}
            width={96}
            height={96}
          />
        </Box>
        <Box flex={1} display="flex" justifyContent={"center"} mb={1} mt={4}>
          <Image alt="Square logo" src={"/square.png"} width={96} height={96} />
        </Box>
        <Box flex={1} display="flex" justifyContent={"center"} mb={1} mt={4}>
          <Image
            alt="Squarespace logo"
            style={{ filter: "opacity(0.5)" }}
            src={"/squarespacelogo.png"}
            width={96}
            height={96}
          />
        </Box>
      </Container>
    </Box>
  );
}

function Metrics() {
  return (
    <Box sx={{ py: 4 }}>
      <Container
        maxWidth="md"
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          px: 3,
        }}
      >
        <Box
          width={200}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              height: 40,
              mb: 2,
              width: 40,
            }}
            variant="rounded"
          >
            <UsersIcon fontSize="small" />
          </Avatar>
          <Typography
            sx={{
              color: "textPrimary",
              textAlign: "center",
            }}
            variant="h4"
          >
            1k+
          </Typography>
          <Typography sx={{ color: "textPrimary" }} variant="overline">
            Pages audited
          </Typography>
        </Box>
        <Box
          width={200}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "secondary.main",
              height: 40,
              mb: 2,
              width: 40,
            }}
            variant="rounded"
          >
            <StarIcon fontSize="small" />
          </Avatar>
          <Typography
            sx={{
              color: "textPrimary",
              textAlign: "center",
            }}
            variant="h4"
          >
            10k+
          </Typography>
          <Typography sx={{ color: "textPrimary" }} variant="overline">
            Defects found
          </Typography>
        </Box>
        <Box
          width={200}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "info.main",
              height: 40,
              mb: 2,
              width: 40,
            }}
            variant="rounded"
          >
            <UsersIcon fontSize="small" />
          </Avatar>
          <Typography
            sx={{
              color: "textPrimary",
              textAlign: "center",
            }}
            variant="h4"
          >
            $100k+
          </Typography>
          <Typography sx={{ color: "textPrimary" }} variant="overline">
            In people hours saved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
