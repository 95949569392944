import axios from "axios";

export async function logToSlack(message: string) {
  if (process.env.NODE_ENV === "production") {
    await axios.post("https://www.n34t.com/api/slack", {
      message,
      channel: "pageauditor",
    });
  } else {
    console.warn("Not logging to slack in dev:", message);
  }
}
