import React from "react";
import { Page } from "n34t-components";
import { PageAuditorFooter } from "./PageAuditorFooter";
import Box from "@mui/material/Box";
import GetStartedButton from "../components/GetStartedButton";
import Button from "@mui/material/Button";
import Link from "next/link";

export default function Layout({
  children,
  title,
  description,
  hideFooter,
}: {
  children: any;
  title?: string;
  description?: string;
  hideFooter?: boolean;
}) {
  console.log(hideFooter);
  return (
    <Page
      title={title ?? "Page Auditor"}
      appName={"Page Auditor"}
      description={description}
      hideFooter
      fullPage
      fixedHeader
      headerChildren={
        <>
          <Link href="/sign-up" passHref>
            <Button
              color="inherit"
              sx={{
                mr: 2,
                color: "white",
                display: {
                  xs: "none",
                  sm: "inline-block",
                },
              }}
            >
              Sign up
            </Button>
          </Link>
          <Link href="/sign-in" passHref>
            <Button color="inherit" sx={{ mr: 2, color: "white" }}>
              Sign in
            </Button>
          </Link>
          <GetStartedButton
            size="regular"
            color="secondary"
            variant="text"
            style={{ color: "white" }}
          />
        </>
      }
      headerLinks={[
        {
          name: "Dashboard",
          url: "/dashboard",
        },
      ]}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        {children}
        {hideFooter === true ? null : (
          <Box mt={2}>
            <PageAuditorFooter />
          </Box>
        )}
      </Box>
    </Page>
  );
}
