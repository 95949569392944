import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "next/link";
import getConfig from "next/config";
import { blue, grey } from "@mui/material/colors";

const featureLinks = [
  {
    title: "Audits",
    href: "/features/audits",
  },
  {
    title: "Monitoring",
    href: "/features/audits",
  },
  {
    title: "Logs",
    href: "/features/audits",
  },
  {
    title: "Analytics",
    href: "/features/audits",
  },
  {
    title: "API",
    href: "/features/audits",
  },
  {
    title: "Canary",
    href: "/features/canary",
  },
];

const compareLinks = [
  {
    title: "Dedicated QA",
    href: "/vs/dedicated-qa",
  },
  {
    title: "Unit tests",
    href: "/vs/unit-testing",
  },
  {
    title: "Integration tests",
    href: "/vs/integration-testing",
  },
];

const companyLinks = [
  {
    title: "About",
    href: "/about",
  },
  {
    title: "Pricing",
    href: "/pricing",
  },
];

const legalLinks = [
  {
    title: "Privacy policy",
    href: "/privacy",
  },
  {
    title: "Terms of service",
    href: "/terms",
  },
];

const supportLinks = [
  {
    title: "Contact",
    href: "/contact",
  },
];

const blogLinks = [
  {
    title: "Blog home",
    href: "/blog",
  },
  {
    title: "Case study",
    href: "/blog/case-study",
  },
];

export function PageAuditorFooter() {
  const config = getConfig();
  return (
    <Box py={16} sx={{ background: blue[500] }}>
      <Grid container px={6}>
        <FooterColumn title="Features" links={featureLinks} />
        <FooterColumn title="Compare" links={compareLinks} />
        <FooterColumn title="Company" links={companyLinks} />
        <FooterColumn title="Blog" links={blogLinks} />
        <FooterColumn title="Support" links={supportLinks} />
        <FooterColumn title="Legal" links={legalLinks} />
        {/*
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {props.logo && <Box mb={1}>{props.logo}</Box>}
          <Link
            passHref
            href={"https://n34t.com"}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="caption" color="primary">
              &copy; {new Date().getFullYear()} n34t, LLC   
            </Typography>
          </Link>
          {props.links?.map((l) => (
            <Link
              key={l.url}
              passHref
              href={l.url}
              style={{ textDecoration: "none" }}
            >
              <Typography
                color="primary"
                className="footer-link"
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                variant="caption"
              >
                {l.name}
              </Typography>
            </Link>
          ))}
          {config?.publicRuntimeConfig?.version && (
            <Typography variant="caption">
              v{config?.publicRuntimeConfig?.version}
            </Typography>
          )}
        </Box>
          */}
        <Box
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          <Link
            passHref
            href={"https://n34t.com"}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="caption" sx={{ color: grey[50] }}>
              &copy; {new Date().getFullYear()} n34t, LLC
            </Typography>
          </Link>
          <Box>
            {config?.publicRuntimeConfig?.version && (
              <Typography variant="caption" sx={{ color: grey[50] }}>
                v{config?.publicRuntimeConfig?.version}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

function FooterColumn({ title, links }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
      <Typography sx={{ color: blue[800] }} variant="h6">
        {title}
      </Typography>
      {links.map((l) => {
        return (
          <Link
            key={l.title}
            style={{ textDecoration: "none" }}
            passHref
            href={l.href}
          >
            <Typography variant="body1" sx={{ color: grey[50], mt: 1 }}>
              {l.title}
            </Typography>
          </Link>
        );
      })}
    </Grid>
  );
}
