import React from "react";
import Link from "next/link";
import Button from "@mui/material/Button";
import { gtm } from "../src/gtm";
import { track } from "../src/analytics";

export default function GetStartedButton(props) {
  return (
    <Link href="/audit" passHref style={{ textDecoration: "none" }}>
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          gtm.push({ event: "get_started" });
          track("Page Auditor get started clicked");
        }}
        {...props}
      >
        Get started
      </Button>
    </Link>
  );
}
