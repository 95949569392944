import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { PricingPlan } from "./pricing-plan";

const plans = [
  {
    pricePerMonth: 0,
    features: ["Up to 5 audits per day"],
    subtext: "Getting started",
    planName: "Page Auditor Free",
    link: "/audit",
    description: "Get started with PageAuditor for free!",
  },
  {
    pricePerMonth: 5,
    features: [
      "Unlimited audits",
      "Unlimited monitored sites",
      "API access",
      "Logs access",
      "Canary access",
    ],
    subtext: "Up and running",
    planName: "Page Auditor Pro",
    recommended: true,
    link: "/sign-up",
    description: "Full access to all PageAuditor features.",
  },
];

export default function PageAuditorPricing() {
  return (
    <Container maxWidth="sm" style={{ marginTop: 16 }}>
      <Paper variant="outlined">
        <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
          {plans.map((plan) => (
            <PricingPlan
              key={plan.planName}
              name={plan.planName}
              cta="Get started"
              currency="$"
              description={plan.description}
              features={plan.features}
              popular={plan.recommended}
              price={plan.pricePerMonth.toString()}
              link={plan.link}
            />
          ))}
        </Box>
      </Paper>
    </Container>
  );
}
